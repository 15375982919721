import React, { useState } from "react";
import B2BForm from './b2bContent/b2bForm.js';
import OnDemandTests from "./onDemandContent/onDemandContent.js"

export function getFormComponentForService(serviceName, onFormSubmitSuccess, showManageTests, handleManageTestsClick, handleTestRunsClick) {
    switch (serviceName) {
        case 'Business to Business':
            return () => <B2BForm onFormSubmitSuccess={onFormSubmitSuccess} />;
        case 'On Demand Test':
            return () => <OnDemandTests
                showManageTests={showManageTests}
                handleManageTestsClick={handleManageTestsClick}
                handleTestRunsClick={handleTestRunsClick}
            />;
        default:
            return () => <div>NO FORM FOUND</div>;
    }
}
