import React, { useState } from 'react';
import axiosInstance from "../../../../../../axiosConfig"; // Adjust the import path as needed
// You can uncomment the CSS import if you have specific styles you want to apply
// import "./AddTestStep.css"; 

function AddTestStep({ testCaseId, onTestStepAdded, currentHighestStepNumber }) {
    const [stepNumber, setStepNumber] = useState(currentHighestStepNumber + 1);
    const [description, setDescription] = useState("");
    const [expectedResult, setExpectedResult] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [feedbackMsg, setFeedbackMsg] = useState({ message: '', type: '' });

    const toggleExpand = (e) => {
        e.preventDefault(); // Prevent the default action
        e.stopPropagation(); // Prevents collapsing the test case on toggle
        setIsExpanded(!isExpanded); // Toggle the form's visibility
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation(); // Stops the submit from affecting parent elements
        if (!testCaseId) return;

        try {
            const response = await axiosInstance.post(`/test-cases/test-steps/create/?test_case_id=${testCaseId}`, {
                step_number: stepNumber,
                description: description,
                expected_result: expectedResult,
            });

            if (response.status === 201) {
                onTestStepAdded(); // Call the callback function to handle the addition
                setFeedbackMsg({ message: 'Test step added successfully!', type: 'success' });
                // Reset the form fields
                setStepNumber(1);
                setDescription("");
                setExpectedResult("");
            } else {
                throw new Error('Failed to create test step');
            }
        } catch (error) {
            console.error('Error creating test step:', error);
            setFeedbackMsg({ message: 'Failed to add test step. Please try again.', type: 'error' });
        }
    };

    return (
        <div className='add-test-step-parent-container' onClick={(e) => e.stopPropagation()}>            <a href="#" onClick={toggleExpand} className="toggle-expand-link">Add Test Step</a>
            {isExpanded && (
                <form onSubmit={handleSubmit} className="add-test-step-form">
                    <div className="test-step-details">
                        <input
                            type="number"
                            placeholder="Step Number"
                            className="input-field step-number-field-disabled"
                            value={stepNumber}
                            readOnly // This makes the field read-only
                        />
                        <input
                            type="text"
                            placeholder="Description"
                            className="input-field"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Expected Result"
                            className="input-field"
                            value={expectedResult}
                            onChange={(e) => setExpectedResult(e.target.value)}
                            required
                        />
                        <button type="submit" className="test-step-submit-btn">Add Test Step</button>
                    </div>
                </form>
            )}
            {feedbackMsg.message && (
                <div className={`feedback-msg ${feedbackMsg.type}`}>{feedbackMsg.message}</div>
            )}
        </div>
    );
}

export default AddTestStep;
