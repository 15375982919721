import React, { useState } from 'react';
import "./contactForm.css"
import axiosInstance from '../../../axiosConfig';

function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus(null);
        try {
            const response = await axiosInstance.post('/contacts/create/', {
                name,
                message
            });
            if (response.status === 201) {
                setSubmitStatus('success');
                setName('');
                setEmail('');
                setMessage('');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitStatus('error');
        }
    };

    return (
        <div className="contact-page">
            <div className="contact-info-side">
                <h1>Get in Touch</h1>
                <p>We'd love to hear from you. Send us a message and we'll respond as soon as possible.</p>
                <div className="contact-details">
                    <p><strong>Phone:</strong> +40 730963536</p>
                    <p><strong>Email:</strong> contact@eliptum.tech</p>
                </div>
            </div>
            <div className="contact-form-side">
                <form onSubmit={handleSubmit}>
                    <h2>Contact Us</h2>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="submit-button">Send Message</button>
                </form>
                {submitStatus === 'success' && <p className="success-message">Message sent successfully!</p>}
                {submitStatus === 'error' && <p className="error-message">Failed to send message. Please try again.</p>}
            </div>
        </div>
    );
}

export default ContactForm;