import React, { useState, useEffect } from 'react';
import './SectionList.css';
import axiosInstance from '../../../../../../axiosConfig'; // Adjust the import path as necessary
import AddTestCase from '../TestCases/AddTestCase';
import AddTestStep from '../TestCases/AddTestStep';
import TestStepList from '../TestCases/TestStepList';

function SectionList({ sections }) {
    const [expandedSection, setExpandedSection] = useState(sections[0]?.id);
    const [expandedTestCaseId, setExpandedTestCaseId] = useState(null);
    const [testCasesCache, setTestCasesCache] = useState(() => {
        const cachedData = localStorage.getItem('testCasesCache');
        return cachedData ? JSON.parse(cachedData) : {};
    });

    const toggleSection = (id) => {
        setExpandedSection(prevSection => prevSection !== id ? id : null);
        if (!testCasesCache[id]) {
            fetchTestCases(id);
        }
    };

    const toggleTestCase = (testCaseId, event) => {
        event.stopPropagation();
        setExpandedTestCaseId(prevId => prevId !== testCaseId ? testCaseId : null);
    };

    const fetchTestCases = async (sectionId) => {
        try {
            const response = await axiosInstance.get(`/sections/test-cases/get-list/?section_id=${sectionId}`);
            if (response.status === 200) {
                const testCases = response.data;
                const testCasesWithSteps = await Promise.all(testCases.map(async (testCase) => {
                    const stepsResponse = await axiosInstance.get(`/test-cases/test-steps/list/?test_case_id=${testCase.id}`);
                    return { ...testCase, steps: stepsResponse.data };
                }));

                const updatedCache = { ...testCasesCache, [sectionId]: { cases: testCasesWithSteps } };
                localStorage.setItem('testCasesCache', JSON.stringify(updatedCache));
                setTestCasesCache(updatedCache);
            }
        } catch (error) {
            console.error('Error fetching test cases:', error);
        }
    };

    const onTestCaseAdded = (sectionId) => {
        localStorage.removeItem('testCasesCache');
        fetchTestCases(sectionId);
    };

    const onTestStepAdded = (testCaseId) => {
        const sectionId = Object.keys(testCasesCache).find(id => testCasesCache[id].cases.some(testCase => testCase.id === testCaseId));
        if (sectionId) fetchTestCases(sectionId);
    };

    useEffect(() => {
        if (sections.length > 0 && !testCasesCache[sections[0].id]) {
            fetchTestCases(sections[0].id);
        }
    }, [sections, testCasesCache]);

    return (
        <div className="sections-list">
            {sections.map((section) => (
                <div
                    key={section.id}
                    className={`project-section ${expandedSection === section.id ? 'expanded' : ''}`}
                    onClick={() => toggleSection(section.id)}
                >
                    <div className={`section-content-context ${expandedSection === section.id ? 'section-selected' : ''}`}>
                        <h4>{section.name}</h4>
                        {expandedSection === section.id && (
                            <div className="expanded-section-panel" onClick={(e) => e.stopPropagation()}>
                                <AddTestCase selectedSectionId={section.id} onTestCaseAdded={() => onTestCaseAdded(section.id)} />
                                <div className="test-cases-container">
                                    <div className="sections-header">
                                        <h4 className="section-header">Test Name</h4>
                                        <h4 className="section-header">Description</h4>
                                        <h4 className="section-header">Prerequisites</h4>
                                    </div>
                                    {testCasesCache[section.id]?.cases?.map((testCase) => {
                                        const currentTestCaseSteps = testCase.steps || [];
                                        const currentHighestStepNumber = currentTestCaseSteps.reduce((max, step) => Math.max(max, step.step_number), 0);

                                        return (
                                            <div
                                                className={`test-case-loaded-container ${expandedTestCaseId === testCase.id ? 'test-case-expanded' : 'test-case-collapsed'}`}
                                                key={testCase.id}
                                                onClick={(event) => toggleTestCase(testCase.id, event)}
                                            >
                                                <div className="test-case-details">
                                                    <div className='test-case-info'>
                                                        <span className="test-name">{testCase.name}</span>
                                                        <span className="test-desc">{testCase.description}</span>
                                                        <div className="test-case-prerequisites">{testCase.prerequisites}</div>
                                                    </div>
                                                    {expandedTestCaseId === testCase.id && (
                                                        <div className="test-case-expanded-content">
                                                            <AddTestStep testCaseId={expandedTestCaseId} onTestStepAdded={() => onTestStepAdded(expandedTestCaseId)} currentHighestStepNumber={currentHighestStepNumber} />
                                                            <TestStepList testSteps={testCase.steps || []} />
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            ))}
        </div>
    );
}

export default SectionList;
