import React, { useState, useEffect, useRef } from "react";
import Typed from 'typed.js';
import "./LandingPage.css";

import SignInSignUpForm from "../sign_in_up/SignInSignUpForm";
import LoggedInUserComponent from "../loggedInUserComponents/LoggedInUserComponent";
import { isAuthenticated } from "../auth/auth";

function LandingPage() {
  const [showSignInSignUp, setShowSignInSignUp] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const typedRef = useRef(null);

  useEffect(() => {
    const authStatus = isAuthenticated();
    setIsLoggedIn(authStatus);

    // Typed.js initialization
    const options = {
      strings: [
        "Innovate",
        "Create",
        "Transform",
        "Elevate Your QA Standards",
      ],
      typeSpeed: 40,
      backSpeed: 50,
      loop: true
    };

    const typed = new Typed(typedRef.current, options);

    // Cleanup function
    return () => {
      typed.destroy();
    };
  }, []);

  const toggleSignInSignUpModal = () => {
    setShowSignInSignUp((prevState) => !prevState);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
  };

  return (
    <>
      {!isLoggedIn && (
        <div className="landing-page-container">
          <div className="content-wrapper">
            <div className="logo-container">
              <h1 className="company-name">
                <span className="eliptum">Eliptum</span>
                <span className="tech">.tech</span>
              </h1>
            </div>
            <h2 className="welcome-text">Welcome to Our Service</h2>
            <div className="typed-container">
              <span ref={typedRef} className="typed-text"></span>
            </div>
            <p className="description">Join us to explore our premium services and innovative solutions.</p>
            <button className='signin-up-btn' onClick={toggleSignInSignUpModal}>
              <span>Sign In / Sign Up</span>
            </button>
          </div>
          <div className="animated-background"></div>
        </div>
      )}
      {isLoggedIn && (
        <div className="user-login-form">
          <LoggedInUserComponent
            isUserLoggedIn={isLoggedIn}
            handleLogout={handleLogout}
            handleLoginSuccess={handleLoginSuccess}
          />
        </div>
      )}
      {showSignInSignUp && (
        <SignInSignUpForm
          toggleForm={toggleSignInSignUpModal}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
    </>
  );
}

export default LandingPage;