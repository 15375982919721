import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosConfig';
import './Orders.css';

function OrdersComponent() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await axiosInstance.get('/orders/');
            setOrders(response.data);
            setLoading(false);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                setOrders([]);
            } else {
                setError('Failed to fetch orders. Please try again.');
            }
            setLoading(false);
        }
    };

    if (loading) return <div className="loading-message">Loading orders...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="orders-container">
            <h2>Your Orders</h2>
            {orders.length === 0 ? (
                <p className="no-orders-message">No orders found.</p>
            ) : (
                <ul className="order-list">
                    {orders.map(order => (
                        <li key={order.id} className="order-item">
                            <h3>Order #{order.id}</h3>
                            <p>Date: {new Date(order.created_at).toLocaleDateString()}</p>
                            <p>Status: {order.status}</p>
                            <p>Total: ${order.total}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default OrdersComponent;