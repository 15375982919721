import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosConfig';
import './ProfileComponent.css';

function ProfileComponent() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [message, setMessage] = useState('');
    const [isCodeButtonDisabled, setIsCodeButtonDisabled] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [fieldError, setFieldError] = useState('');

    useEffect(() => {
        fetchUserData();
        checkRemainingTime();
    }, []);

    useEffect(() => {
        let timer;
        if (remainingTime > 0) {
            timer = setInterval(() => {
                const newRemainingTime = calculateRemainingTime();
                if (newRemainingTime <= 0) {
                    setIsCodeButtonDisabled(false);
                    setRemainingTime(0);
                    localStorage.removeItem('codeRequestTime');
                } else {
                    setRemainingTime(newRemainingTime);
                }
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [remainingTime]);

    const fetchUserData = async () => {
        try {
            const response = await axiosInstance.get('/accounts/userinfo/');
            setUserData(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch user data. Please try again.');
            setLoading(false);
        }
    };

    const calculateRemainingTime = () => {
        const storedTime = localStorage.getItem('codeRequestTime');
        if (storedTime) {
            const elapsedTime = Math.floor((Date.now() - parseInt(storedTime)) / 1000);
            return Math.max(60 - elapsedTime, 0);
        }
        return 0;
    };

    const checkRemainingTime = () => {
        const remainingTime = calculateRemainingTime();
        if (remainingTime > 0) {
            setRemainingTime(remainingTime);
            setIsCodeButtonDisabled(true);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
        setNewEmail(userData.email);
    };

    const handleSendCode = async () => {
        try {
            const response = await axiosInstance.post('/account/request-email-reset/', { email: userData.email });
            if (response.status === 200) {
                setMessage(response.data.message);
                setIsCodeButtonDisabled(true);
                const currentTime = Date.now();
                localStorage.setItem('codeRequestTime', currentTime.toString());
                setRemainingTime(60);
            }
        } catch (err) {
            setMessage(err.response?.data?.error || 'Failed to send reset code.');
        }
    };

    const handleChangeEmail = async () => {
        if (!newEmail || !resetCode) {
            setFieldError('Please enter both new email and reset code.');
            return;
        }

        setFieldError(''); // Clear the field error if both fields are filled

        try {
            const response = await axiosInstance.post('/account/change-email/', { new_email: newEmail, reset_code: resetCode });
            setMessage(response.data.message);
            if (response.status === 200) {
                localStorage.setItem("access_token", response.data.access_token);
                setIsEditing(false);
                fetchUserData();
                setNewEmail('');
                setResetCode('');
            }
        } catch (err) {
            setMessage(err.response?.data?.error || 'Failed to change email.');
        }
    };

    if (loading) return <div className="loading-message">Loading user data...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="profile-container">
            <h2>Your Profile</h2>
            {userData && (
                <div className="profile-info">
                    <div className="profile-item">
                        <h3>Username</h3>
                        <p>{userData.username}</p>
                    </div>
                    <div className="profile-item">
                        <h3>Email</h3>
                        {isEditing ? (
                            <div className="email-edit">
                                <input
                                    type="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    placeholder="New Email"
                                    required
                                />
                                <input
                                    type="text"
                                    value={resetCode}
                                    onChange={(e) => setResetCode(e.target.value)}
                                    placeholder="Reset Code"
                                    required
                                />
                                <button
                                    className='send-code-button-profile'
                                    onClick={handleSendCode}
                                    disabled={isCodeButtonDisabled}
                                >
                                    {isCodeButtonDisabled
                                        ? `Resend code in ${remainingTime}s`
                                        : 'Send Reset Code'}
                                </button>
                                <button onClick={handleChangeEmail}>
                                    Change Email
                                </button>
                                {fieldError && <div className="error-message">{fieldError}</div>}
                            </div>
                        ) : (
                            <>
                                <p>{userData.email}</p>
                                <button onClick={handleEditClick}>Change Email</button>
                            </>
                        )}
                    </div>
                    <div className="profile-item">
                        <h3>Phone Number</h3>
                        <p>{userData.phone_number || 'Not provided'}</p>
                    </div>
                    <div className="profile-item">
                        <h3>Account Status</h3>
                        <p>{userData.is_active ? 'Active' : 'Inactive'}</p>
                    </div>
                    <div className="profile-item">
                        <h3>Member Since</h3>
                        <p>{new Date(userData.created_at).toLocaleDateString()}</p>
                    </div>
                </div>
            )}
            {message && <div className="message">{message}</div>}
        </div>
    );
}

export default ProfileComponent;