import React, { useState } from 'react';
import axiosInstance from "../../../../../../axiosConfig"; // Adjust the import path as needed
import "./AddTestCase.css"; // New CSS file for AddTestCase specific styles


function AddTestCase({ selectedSectionId, onTestCaseAdded }) {
    const [testCaseName, setTestCaseName] = useState("");
    const [description, setDescription] = useState("");
    const [prerequisites, setPrerequisites] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [feedbackMsg, setFeedbackMsg] = useState({ message: '', type: '' }); // New state for feedback messages
    const [testSteps, setTestSteps] = useState([{ step_number: 1, description: '', expected_result: '' }]);

    const toggleExpand = (e) => {
        e.preventDefault();
        e.stopPropagation(); // Add this to stop the click from reaching the parent
        setIsExpanded(!isExpanded);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation(); // Also useful here to prevent form submission from bubbling
        if (!selectedSectionId) return;

        try {
            // Create the test case
            const response = await axiosInstance.post(`/sections/test-cases/create/?section_id=${selectedSectionId}`, {
                name: testCaseName,
                description: description,
                prerequisites: prerequisites,
            });

            if (response.status === 201) {
                // Extract the ID of the newly created test case
                const testCaseId = response.data.id;
                setIsExpanded(false);

                // Create all test steps for this test case
                const createTestStepPromises = testSteps.map((step) =>
                    axiosInstance.post(`/test-cases/test-steps/create/?test_case_id=${testCaseId}`, {
                        step_number: step.step_number,
                        description: step.description,
                        expected_result: step.expected_result,
                    })
                );

                // Wait for all test steps to be created
                await Promise.all(createTestStepPromises);

                onTestCaseAdded(); // Refresh the list of test cases
                setFeedbackMsg({ message: 'Test case and steps added successfully!', type: 'success' });
                setTimeout(() => setFeedbackMsg({ message: '', type: '' }), 3000);
                // Reset the form
                setTestCaseName("");
                setDescription("");
                setPrerequisites("");
                setTestSteps([{ step_number: 1, description: '', expected_result: '' }]);
            } else {
                throw new Error('Failed to create test case');
            }
        } catch (error) {
            console.error('Error creating test case:', error);
            setFeedbackMsg({ message: 'Failed to add test case. Please try again.', type: 'error' });
            setTimeout(() => setFeedbackMsg({ message: '', type: '' }), 3000);
        }
    };


    //TEST STEPS -----------------
    const handleAddStep = () => {
        // Add a new step with the next step number
        setTestSteps([
            ...testSteps,
            { step_number: testSteps.length + 1, description: '', expected_result: '' },
        ]);
    };

    const handleStepChange = (index, field, value) => {
        const updatedSteps = [...testSteps];
        updatedSteps[index][field] = value;
        setTestSteps(updatedSteps);
    };

    const handleRemoveStep = (indexToRemove) => {
        // Filter out the step to remove
        const updatedSteps = testSteps.filter((_, index) => index !== indexToRemove)
            // Optional: Adjust step numbers
            .map((step, index) => ({ ...step, step_number: index + 1 }));

        setTestSteps(updatedSteps);
    };
    //TEST STEPS END -----------------

    return (
        <div className='tc-add-test-case-parent-container'>
            <a href="#" onClick={toggleExpand} className="tc-toggle-expand-link">Add Test Case</a>
            {isExpanded && (
                <div className="tc-add-test-case-container">
                    <form onSubmit={handleSubmit} className="tc-add-test-case-form">
                        <div className="add-test-case-details">
                            <input
                                type="text"
                                placeholder="Test Case Name"
                                className="tc-input-field"
                                value={testCaseName}
                                onChange={(e) => setTestCaseName(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Description"
                                className="tc-input-field"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Prerequisites"
                                className="tc-input-field"
                                value={prerequisites}
                                onChange={(e) => setPrerequisites(e.target.value)}
                            />
                        </div>
                        <div className="add-test-steps-container">
                            {testSteps.map((step, index) => (
                                <div key={index} className="to-add-test-step">
                                    <input
                                        type="number"
                                        placeholder="Step Number"
                                        value={step.step_number}
                                        onChange={(e) => handleStepChange(index, 'step_number', e.target.value)}
                                        className="tc-input-field"
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        placeholder="Description"
                                        value={step.description}
                                        onChange={(e) => handleStepChange(index, 'description', e.target.value)}
                                        className="tc-input-field"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Expected Result"
                                        value={step.expected_result}
                                        onChange={(e) => handleStepChange(index, 'expected_result', e.target.value)}
                                        className="tc-input-field"
                                    />
                                    <button type="button" onClick={() => handleRemoveStep(index)} className="tc-remove-step-btn">Remove</button>
                                </div>
                            ))}
                        </div>
                        <button type="button" onClick={handleAddStep} className="tc-add-step-btn"><span class="text">Add Step</span><span>Submit Step</span></button>
                        <button type="submit" className="tc-section-submit-btn"><span class="text">Create Test Case</span><span>Submit TestCase</span></button>
                    </form>

                </div>
            )}
            {feedbackMsg.message && (
                <div className={`feedback-msg ${feedbackMsg.type}`}>{feedbackMsg.message}</div>
            )}
        </div>
    );
}

export default AddTestCase;
