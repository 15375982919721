import React, { useState } from 'react';

function ConfirmDeleteModal({ projectId, onClose, onDelete }) {
    const [deleteMessage, setDeleteMessage] = useState('');
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

    const handleDelete = async () => {
        onDelete(projectId)
            .then((message) => {
                setDeleteMessage(message);
                setIsDeleteSuccess(true);
                setTimeout(onClose, 2000); // Optionally close the modal after a delay
            })
            .catch((message) => {
                setDeleteMessage(message);
                setIsDeleteSuccess(false);
                // Don't close the modal automatically on failure to allow reading the message
            });
    };


    return (
        <div className="modal-background">
            <div className="modal-content">
                {/* Conditional rendering for deleteMessage */}

                <p>Are you sure you want to delete this project?</p>
                {deleteMessage && (
                    <p style={{ color: isDeleteSuccess ? 'green' : 'red' }}>{deleteMessage}</p>
                )}
                <button onClick={handleDelete}>Yes</button>
                <button onClick={onClose}>No</button>
            </div>
        </div>
    );
}

export default ConfirmDeleteModal;
