import axiosInstance from "../../../../../../../axiosConfig";
import React, { useState } from "react";
import "./addProjectModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function AddProjectModal({ onClose, onProjectAdded }) { // Notice the added prop
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [message, setMessage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post("/projects/create", {
                name: projectName,
                description: projectDescription,
            });
            if (response.status === 201) { // Assuming 201 for created
                setProjectName(""); // Reset form fields
                setProjectDescription("");
                setMessage({ type: 'success', content: 'Project added successfully!' });
                onProjectAdded(); // Trigger the callback to re-fetch projects
            } else {
                throw new Error('Error creating project');
            }
        } catch (error) {
            setMessage({ type: 'error', content: 'Error creating project: ' + error.response.data.message });
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="modal-close" onClick={onClose}>&times;</span>
                <h3>Create New Project</h3>

                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        className="project-name-input"
                        placeholder="Project Name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                    />
                    <textarea
                        className="project-desc-textarea"
                        placeholder="Project Description"
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                        required
                    ></textarea>
                    <div className="create-project-btn-container">
                        {message && (
                            <div className={`message ${message.type}`}>
                                {message.type === 'success' ? (
                                    <span className="success-icon">&#10003;</span>
                                ) : (
                                    <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                )}
                                <p className={message.type}>{message.content}</p>
                            </div>
                        )}
                        <button className='create-project-btn' type="submit">Create Project</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProjectModal;