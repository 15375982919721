import React from 'react';
import "./TestStep.css";

const TestStepList = ({ testSteps }) => {
    return (
        <div className="test-steps-loaded-container" onClick={(e) => e.stopPropagation()}>
            {testSteps.map((step) => (
                <div key={step.id} className="test-step">
                    <div className='step-number'>{step.step_number}</div>
                    <div className='step-desc'>{step.description}</div>
                    <div className='step-expected'>{step.expected_result}</div>
                </div>
            ))}
        </div>
    );
};

export default TestStepList;