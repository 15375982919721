import React, { useState } from 'react';
import ConfirmDeleteModal from './ConfirmDeletePrModal'; // You'll create this component next

function ProjectList({ projects, toggleModal, onProjectSelect, onDeleteProject }) {
    const [activeButtonId, setActiveButtonId] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);

    const handleButtonClick = (projectId) => {
        if (showConfirmDelete) {
            setShowConfirmDelete(false); // Close the delete modal if open
        }
        onProjectSelect(projectId);
        setActiveButtonId(projectId);
    };


    const handleDeleteIconClick = (projectId, event) => {
        event.stopPropagation(); // Prevent the button's onClick from firing
        setShowConfirmDelete(currentState => !currentState); // Toggle the visibility of the confirmation modal
        setProjectToDelete(projectId); // Set the ID for potential deletion
    };

    return (
        <>
            <div className="project-container">
                <div className="projects-scroll-container">
                    {projects.map((project) => (
                        <button
                            key={project.id}
                            className={`loaded-project-button ${activeButtonId === project.id ? 'active' : ''}`}
                            onClick={() => handleButtonClick(project.id)}>
                            {project.name}
                            <i className="fas fa-trash project-delete-icon" onClick={(e) => handleDeleteIconClick(project.id, e)}></i>
                        </button>
                    ))}
                </div>
                <button className="add-project-btn" onClick={toggleModal}>
                </button>
            </div>
            {showConfirmDelete && (
                <ConfirmDeleteModal
                    projectId={projectToDelete}
                    onClose={() => setShowConfirmDelete(false)}
                    onDelete={onDeleteProject} // Use the prop here
                />
            )}
        </>
    );
}

export default ProjectList;
