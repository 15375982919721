import React, { useState, useEffect, useRef } from "react";
import "./SignInSignUpForm.css";
import {
  FaUser,
  FaLock,
  FaLockOpen,
  FaExclamationCircle,
  FaShieldAlt,
  FaEnvelope,
  FaCheckCircle
}

  from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "./phone_style.css";
import { encryptData } from "./encryptUtil";
import axiosInstance from "../../axiosConfig";

const SignInSignUpForm = ({ toggleForm, onLoginSuccess }) => {
  const [formType, setFormType] = useState("signin");
  const [rememberMe, setRememberMe] = useState(false);
  const formRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [highlight_red, sethighlight_red] = useState("");
  const [login_email, setLoginEmail] = useState("");
  const [login_password, setLoginPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [isCodeButtonDisabled, setIsCodeButtonDisabled] = useState(false);
  const [canResendCode, setCanResendCode] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    const checkClickOutside = (e) => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        toggleForm();
      }
    };

    document.addEventListener("mousedown", checkClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkClickOutside);
    };
  }, [toggleForm]);

  useEffect(() => {
    setErrorMessage("");
  }, [formType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    sethighlight_red("");

    if (formType === "signin") {
      if (login_email.length === 0) {
        setErrorMessage("Email cannot be empty");
        sethighlight_red("email");
        return;
      }

      if (!validateEmail(login_email)) {
        setErrorMessage("Invalid email format");
        sethighlight_red("email");
        return;
      }

      if (!validateEmail(login_email)) {
        setErrorMessage("Invalid email format");
        sethighlight_red("email");
        return;
      }

      if (!login_password || login_password.length === 0) {
        setErrorMessage("No password provided");
        sethighlight_red("password");
        return;
      }
      try {
        const public_key_r = await axiosInstance.get("/get-public-key", {
          withCredentials: true,
        });

        const publicKey = public_key_r.data.publicKey;
        const csrfToken = public_key_r.data.csrf_token;
        const encryptedPassword = await encryptData(
          login_password,
          publicKey,
          false
        );

        const response = await axiosInstance.post(
          "/user/login",
          {
            email: login_email,
            password: encryptedPassword,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
            },
          }
        );

        if (response.status === 200) {
          setSuccessMessage("You have been successfully logged in.");
          setTimeout(() => {
            localStorage.setItem("csrf_token", csrfToken);
            localStorage.setItem("access_token", response.data.access_token);
            onLoginSuccess();
            toggleForm();
          }, 1000);
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        }
      }
    } else if (formType === "signup") {
      if (!username || username.length < 3 || username.length > 15) {
        setErrorMessage("Username must be 3-15 characters long");
        sethighlight_red("username");
        return;
      }
      if (!validateEmail(email)) {
        setErrorMessage("Invalid email format");
        sethighlight_red("email");
        return;
      }
      if (!password || password.length < 8) {
        setErrorMessage("Password must be at least 8 characters long");
        sethighlight_red("password");
        return;
      }

      try {
        const public_key_r = await axiosInstance.get("/get-public-key", {
          withCredentials: true,
        });
        const publicKey = public_key_r.data.publicKey;
        const csrfToken = public_key_r.data.csrf_token;
        const encryptedPassword = await encryptData(password, publicKey, false);

        const response = await axiosInstance.post(
          "/user/create",
          {
            username,
            password: encryptedPassword,
            email,
            phone_number: phoneNumber,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
            },
          }
        );

        if (response.status === 201) {
          setSuccessMessage("Account created successfully!");
          setTimeout(() => {
            setFormType("signin");
          }, 1000);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.message);
          if (error.response.data.message === "Email already used") {
            sethighlight_red("email");
          } else if (error.response.data.message === "User already exists") {
            sethighlight_red("username");
          }
        } else {
          setErrorMessage("An unexpected error occurred" + error);
        }
      }
    }
  };
  useEffect(() => {
    if (formType === "reset") {
      const lastCodeSentTime = localStorage.getItem('lastCodeSentTime');
      if (lastCodeSentTime) {
        const elapsedTime = Math.floor((Date.now() - parseInt(lastCodeSentTime)) / 1000);
        if (elapsedTime < 60) {
          setCodeSent(true);
          setRemainingTime(60 - elapsedTime);
        }
      }
    }
  }, [formType]);

  useEffect(() => {
    let timer;
    if (codeSent && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [codeSent, remainingTime]);

  const handleSendCode = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!validateEmail(resetEmail)) {
      setErrorMessage("Invalid email format");
      sethighlight_red("reset-email");
      return;
    }

    const lastCodeSentTime = localStorage.getItem('lastCodeSentTime');
    if (lastCodeSentTime) {
      const elapsedTime = Math.floor((Date.now() - parseInt(lastCodeSentTime)) / 1000);
      if (elapsedTime < 60) {
        setCodeSent(true);
        setRemainingTime(60 - elapsedTime);
        setErrorMessage(`Please wait ${60 - elapsedTime} seconds before requesting a new code.`);
        return;
      }
    }

    try {
      const response = await axiosInstance.post("/user/password-reset-request", {
        email: resetEmail,
      });
      if (response.status === 200) {
        setSuccessMessage("Reset code sent to your email.");
        setCodeSent(true);
        setRemainingTime(60);
        localStorage.setItem('lastCodeSentTime', Date.now().toString());
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
        "An error occurred while sending the reset code."
      );
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    sethighlight_red("");

    if (!resetCode) {
      setErrorMessage("Please enter the reset code");
      sethighlight_red("reset-code");
      return;
    }

    if (!newPassword || newPassword.length < 8) {
      setErrorMessage("New password must be at least 8 characters long");
      sethighlight_red("new-password");
      return;
    }

    try {
      const response = await axiosInstance.post("/user/password-reset", {
        email: resetEmail,
        reset_code: resetCode,
        new_password: newPassword,
      });

      if (response.status === 200) {
        setSuccessMessage("Password reset successfully!");
        setTimeout(() => {
          setFormType("signin");
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.new_password && error.response.data.new_password.includes("This password is too common.")) {
          setErrorMessage("This password is too common.");
          sethighlight_red("new-password");
        } else {
          setErrorMessage(error.response.data.message || "An unexpected error occurred.");
        }
      } else {
        setErrorMessage(
          error.response?.data?.message || "An error occurred while resetting the password."
        );
      }
    }
  };


  const handleCheckboxClick = () => {
    setRememberMe(!rememberMe);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const getHeader = () => {
    switch (formType) {
      case "signin":
        return "Sign In";
      case "signup":
        return "Sign Up";
      case "reset":
        return "Password Recovery";
      default:
        return "Welcome";
    }
  };

  return (
    <div className="overlay-signin">
      <div className={`signin-signup-modal ${formType}`} ref={formRef}>
        <h1>{getHeader()}</h1>
        <button className="close-button" onClick={toggleForm}>
          &times;
        </button>

        <form
          className="form-content"
          onSubmit={formType === "reset" ? handleResetPassword : handleSubmit}
        >
          {formType === "signin" && (
            <div className="singup-box">
              <div
                className={`input-box ${highlight_red === "email" ? "error_field_highlight" : ""
                  }`}
              >
                <input
                  name="email"
                  type="text"
                  placeholder="E-Mail"
                  value={login_email}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
                <FaEnvelope className="icon-login" />
              </div>
              <div
                className={`input-box ${highlight_red === "password" ? "error_field_highlight" : ""
                  }`}
              >
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={login_password}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                <FaLock className="icon-login" />
              </div>
              <div className="checkbox-container">
                <input
                  name="checkbox"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  className="futuristic-checkbox"
                />
                <label
                  htmlFor="rememberMe"
                  className="checkbox-label"
                  onClick={handleCheckboxClick}
                >
                  Remember Me
                </label>
              </div>
              {errorMessage && (
                <div className="error-container">
                  <FaExclamationCircle className="error-icon" />
                  <span className="error-message-login">{errorMessage}</span>
                </div>
              )}
            </div>
          )}
          {formType === "signup" && (
            <>
              <div
                className={`input-box ${highlight_red === "username" ? "error_field_highlight" : ""
                  }`}
              >
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <FaUser className="icon-login" />
              </div>
              <div
                className={`input-box ${highlight_red === "password" ? "error_field_highlight" : ""
                  }`}
              >
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FaLock className="icon-login" />
              </div>
              <div
                className={`input-box ${highlight_red === "email" ? "error_field_highlight" : ""
                  }`}
              >
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FaEnvelope className="icon-login" />
              </div>
              <div
                className={`input-box ${highlight_red === "phone" ? "error_field_highlight" : ""
                  }`}
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber("+" + value)}
                  country="ro"
                />
              </div>
              {errorMessage && (
                <div className="error-container fade-in">
                  <FaExclamationCircle className="error-icon" />
                  <span className="error-message-login">{errorMessage}</span>
                </div>
              )}
            </>
          )}

          {formType === "reset" && (
            <>
              <div className={`input-box ${highlight_red === "reset-email" ? "error_field_highlight" : ""}`}>
                <input
                  type="email"
                  placeholder="Email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  disabled={codeSent}
                />
                <FaUser className="icon-login" />
              </div>
              {!codeSent && (
                <button className="send-code-btn" onClick={handleSendCode}>
                  Send Reset Code
                </button>
              )}
              {codeSent && (
                <>
                  <div className={`input-box ${highlight_red === "reset-code" ? "error_field_highlight" : ""}`}>
                    <input
                      type="text"
                      placeholder="Reset Code"
                      value={resetCode}
                      onChange={(e) => setResetCode(e.target.value)}
                    />
                    <FaShieldAlt className="icon-login" />
                  </div>
                  <div className={`input-box ${highlight_red === "new-password" ? "error_field_highlight" : ""}`}>
                    <input
                      type="password"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FaLockOpen className="icon-login" />
                  </div>
                  {remainingTime > 0 ? (
                    <p className="resend-timer">Resend code in {remainingTime}s</p>
                  ) : (
                    <button
                      className="resend-code-btn"
                      onClick={handleSendCode}
                    >
                      Resend Code
                    </button>
                  )}
                </>
              )}
              {errorMessage && (
                <div className="error-container fade-in">
                  <FaExclamationCircle className="error-icon" />
                  <span className="error-message-login">{errorMessage}</span>
                </div>
              )}
            </>
          )}

          <button className="submitbtn" type="submit">
            {formType === "signin"
              ? "Sign In"
              : formType === "signup"
                ? "Sign Up"
                : codeSent
                  ? "Reset Password"
                  : "Reset Password"}
          </button>
        </form>
        {successMessage && (
          <div className="success-container-signinup">
            <FaCheckCircle className="success-icon" />
            <span className="success-message-signinup">{successMessage}</span>
          </div>
        )}
        <div className="account-actions">
          {formType !== "reset" && (
            <div className="signup-box">
              {formType === "signin" ? (
                <p>Don't have an account?</p>
              ) : (
                <p>Already have an account?</p>
              )}
              <a
                href="#"
                onClick={() =>
                  setFormType(formType === "signin" ? "signup" : "signin")
                }
              >
                {formType === "signin" ? "Sign Up" : "Sign In"}
              </a>
            </div>
          )}

          {formType === "signin" && (
            <a href="#" className="forgot-pass" onClick={() => setFormType("reset")}>
              Forgot Password?
            </a>
          )}

          {formType === "reset" && (

            <a href="#" className="forgot-pass" onClick={() => setFormType("signin")}>
              Back to Sign In
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInSignUpForm;
