import React, { useState, useEffect } from 'react';
import './b2bForm.css';
import { motion } from 'framer-motion';
import axiosInstance from '../../../../../axiosConfig';

const B2BForm = ({ onFormSubmitSuccess }) => {
    const [formData, setFormData] = useState({
        company_name: '',
        intention: '',
        company_address: '',
        company_email: '',
        company_phone: '',
        tax_id: '',
        project_duration: '',
        number_of_resources: '',
        contract_scope: '',
        expected_cost: ''
    });
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [messageColor, setMessageColor] = useState('#000');
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const savedData = localStorage.getItem('b2bFormData');
        if (savedData) {
            setFormData(JSON.parse(savedData));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
        localStorage.setItem('b2bFormData', JSON.stringify({ ...formData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axiosInstance.post('/orders/create', { ...formData, service_name: 'Business to Business' });
            localStorage.removeItem('b2bFormData');
            setSubmissionMessage('Form submitted successfully! You will be contacted shortly');
            setMessageColor('#4CAF50');
            setShowMessage(true);
            setFormData({
                company_name: '',
                intention: '',
                company_address: '',
                company_email: '',
                company_phone: '',
                tax_id: '',
                project_duration: '',
                number_of_resources: '',
                contract_scope: '',
                expected_cost: ''
            });
            if (onFormSubmitSuccess) {
                onFormSubmitSuccess();
            }
        } catch (error) {
            console.error('Form submission error:', error);
            let message = 'Form submission failed! Contact an administrator';
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        message = 'Bad request - please check your input.';
                        break;
                    case 404:
                        message = 'Server could not find the requested resource.';
                        break;
                    case 500:
                        message = 'Internal server error. Please try again later.';
                        break;
                    default:
                        message = `Form submission failed! ${error.response.data}`;
                        break;
                }
            }
            setSubmissionMessage(message);
            setMessageColor('#FF6347');
            setShowMessage(true);
        }
    };

    return (
        <form className="b2b-form" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="company_name">Company Name:</label>
                <input
                    className='order-input'
                    type="text"
                    id="company_name"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                    placeholder="Company Name"
                />
            </div>
            <div className="form-group">
                <label htmlFor="project_duration">Project Duration:</label>
                <input
                    className='order-input'
                    type="text"
                    id="project_duration"
                    name="project_duration"
                    value={formData.project_duration}
                    onChange={handleChange}
                    placeholder="Project Duration"
                />
            </div>
            <div className="form-group">
                <label htmlFor="number_of_resources">Number of Resources:</label>
                <input
                    className='order-input'
                    type="text"
                    id="number_of_resources"
                    name="number_of_resources"
                    value={formData.number_of_resources}
                    onChange={handleChange}
                    placeholder="Number of Resources"
                />
            </div>
            <div className="form-group">
                <label htmlFor="contract_scope">Contract Scope:</label>
                <input
                    className='order-input'
                    type="text"
                    id="contract_scope"
                    name="contract_scope"
                    value={formData.contract_scope}
                    onChange={handleChange}
                    placeholder="Contract Scope"
                />
            </div>
            <div className="form-group">
                <label htmlFor="expected_cost">Expected Cost:</label>
                <input
                    className='order-input'
                    type="text"
                    id="expected_cost"
                    name="expected_cost"
                    value={formData.expected_cost}
                    onChange={handleChange}
                    placeholder="Expected Cost"
                />
            </div>
            <div className="form-group">
                <label htmlFor="company_address">Company Address:</label>
                <input
                    className='order-input'
                    type="text"
                    id="company_address"
                    name="company_address"
                    value={formData.company_address}
                    onChange={handleChange}
                    placeholder="Company Address"
                />
            </div>
            <div className="form-group">
                <label htmlFor="company_email">Company Email:</label>
                <input
                    className='order-input'
                    type="email"
                    id="company_email"
                    name="company_email"
                    value={formData.company_email}
                    onChange={handleChange}
                    placeholder="Company Email"
                />
            </div>
            <div className="form-group">
                <label htmlFor="company_phone">Company Phone:</label>
                <input
                    className='order-input'
                    type="tel"
                    id="company_phone"
                    name="company_phone"
                    value={formData.company_phone}
                    onChange={handleChange}
                    placeholder="Company Phone"
                />
            </div>
            <div className="form-group">
                <label htmlFor="tax_id">Tax/VAT ID:</label>
                <input
                    className='order-input'
                    type="text"
                    id="tax_id"
                    name="tax_id"
                    value={formData.tax_id}
                    onChange={handleChange}
                    placeholder="Tax/VAT ID"
                />
            </div>
            <div className="form-group">
                <label htmlFor="intention">Intentions / Requirements:</label>
                <textarea
                    id="intention"
                    name="intention"
                    value={formData.intention}
                    onChange={handleChange}
                    placeholder="Your intentions / requirements (max 3000 chars)"
                    maxLength="3000"
                    className='order-input'
                />
            </div>
            <div className="form-group">
                <div className="button-container">
                    <div
                        className={`message-container ${showMessage ? 'message-visible' : ''}`}
                        style={{ color: messageColor }}
                    >
                        {submissionMessage}
                    </div>
                    <motion.button
                        whileHover={{ scale: 1.04 }}
                        whileTap={{ scale: 0.95 }}
                        animate={{ backgroundColor: submissionMessage.includes('successfully') ? "#4CAF50" : submissionMessage.includes('failed') ? "#FF6347" : "#5c7cfa" }}
                        transition={{ duration: 0.1 }}
                        onClick={() => setShowMessage(false)}
                        type="submit"
                        className='b2b_submit'
                    >
                        Submit
                    </motion.button>
                </div>
            </div>
        </form>
    );
};

export default B2BForm;