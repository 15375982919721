import React, { useState, useEffect } from "react";
import AddProjectModal from "./Projects/addProjectModal/addProjectModal";
import ProjectList from "./Projects/ProjectList";
import SectionList from "./Sections/SectionList";
import AddSection from "./Sections/AddSection";

import "./manageTests.css";
import axiosInstance from "../../../../../axiosConfig";

function ManageTests() {
    const [showModal, setShowModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [sections, setSections] = useState([]);

    const handleProjectSelect = (projectId) => {
        setSelectedProjectId(projectId);
    };

    const fetchProjects = async () => {
        const cachedProjects = localStorage.getItem('projects');
        if (cachedProjects) {
            setProjects(JSON.parse(cachedProjects));
            return;
        }

        try {
            const response = await axiosInstance.get('/projects/list/');
            if (response.status === 200) {
                localStorage.setItem('projects', JSON.stringify(response.data));
                setProjects(response.data);
            } else {
                throw new Error('Failed to fetch projects');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const fetchSections = async () => {
        if (!selectedProjectId) return;

        try {
            const cachedSections = JSON.parse(localStorage.getItem('sections') || '{}');
            if (!cachedSections[selectedProjectId]) {
                const response = await axiosInstance.get(`/projects/sections/list/?project_id=${selectedProjectId}`);
                if (response.status === 200) {
                    cachedSections[selectedProjectId] = response.data;
                    localStorage.setItem('sections', JSON.stringify(cachedSections));
                    setSections(response.data);
                } else {
                    throw new Error('Failed to fetch sections');
                }
            } else {
                setSections(cachedSections[selectedProjectId]);
            }
        } catch (error) {
            console.error('Error fetching sections:', error);
        }
    };

    useEffect(() => {
        fetchSections();
    }, [selectedProjectId]);

    const onProjectAdded = async () => {
        localStorage.removeItem('projects');
        await fetchProjects();
    };

    const onSectionAdded = async () => {
        const cachedSections = JSON.parse(localStorage.getItem('sections') || '{}');
        delete cachedSections[selectedProjectId];
        localStorage.setItem('sections', JSON.stringify(cachedSections));
        await fetchSections();
    };

    async function onDeleteProject(projectId) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axiosInstance.delete(`/projects/delete/?project_id=${projectId}`);
                if (response.status === 204) {
                    const updatedProjects = projects.filter(project => project.id !== projectId);
                    setProjects(updatedProjects);
                    localStorage.setItem('projects', JSON.stringify(updatedProjects));
                    resolve("Project deleted successfully.");
                } else {
                    reject("Failed to delete project.");
                }
            } catch (error) {
                console.error('Error deleting project:', error);
                reject("Error deleting project.");
            }
        });
    }


    return (
        <div className="manage-tests">
            <ProjectList projects={projects} toggleModal={toggleModal} onProjectSelect={handleProjectSelect} onDeleteProject={onDeleteProject} />            {showModal && <AddProjectModal onClose={toggleModal} onProjectAdded={onProjectAdded} />}

            <div className="sections-container">
                {selectedProjectId && (
                    <>
                        <AddSection selectedProjectId={selectedProjectId} onSectionAdded={onSectionAdded} />
                        <SectionList sections={sections} />
                    </>
                )}
            </div>
        </div>
    );
}

export default ManageTests;
