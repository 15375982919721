import axios from 'axios';

// Axios instance creation
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST || 'http://127.0.0.1:8000/',
    withCredentials: true,
});

// Function to fetch and set CSRF token
async function fetchAndSetCSRFToken() {
    try {
        const response = await axiosInstance.get('/accommodate-user');
        const csrfToken = response.data.csrf_token;
        axiosInstance.defaults.headers.common['X-CSRFToken'] = csrfToken;
        console.log('CSRF token fetched and set:', csrfToken);
    } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
    }
}

// Add a request interceptor to add the bearer token before each request
axiosInstance.interceptors.request.use((config) => {
    // Fetch the token from local storage
    const token = localStorage.getItem('access_token');
    // If the token exists, add it to the request's Authorization header
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});

fetchAndSetCSRFToken();

export default axiosInstance;
