import React from "react";
import LandingPage from "./app_components/landingPage/LandingPage";
import './main-app.css'

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
