import React, { useState } from 'react';
import axiosInstance from "../../../../../../axiosConfig"; // Adjust the import path as needed
import "./AddSection.css";

function AddSection({ selectedProjectId, onSectionAdded }) {
    const [sectionName, setSectionName] = useState("");
    const [description, setDescription] = useState("");
    const [isExpanded, setIsExpanded] = useState(false); // State to control visibility

    const toggleExpand = (e) => {
        e.preventDefault(); // Prevent the default action
        setIsExpanded(!isExpanded); // Toggle the form's visibility
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedProjectId) return;

        try {
            await axiosInstance.post(`/projects/sections/create/?project_id=${selectedProjectId}`, {
                section_name: sectionName,
                description: description,
            });
            setIsExpanded(false)
            onSectionAdded();
            setSectionName("");
            setDescription("");
        } catch (error) {
            console.error('Error creating section:', error);
        }
    };

    return (
        <div className='add-section-parent-container'>
            <a href="#" onClick={toggleExpand} className="toggle-expand-link">Add Section</a>
            {isExpanded && (
                <div className="add-section-container">
                    <form onSubmit={handleSubmit} className="add-section-form">
                        <input
                            type="text"
                            placeholder="Section Name"
                            className="sec-input-field"
                            value={sectionName}
                            onChange={(e) => setSectionName(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Section Description"
                            className="sec-input-field"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                        <button type="submit" className="section-submit-btn">+</button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default AddSection;
