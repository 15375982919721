import React, { useState } from "react";
import ManageTests from "../testManagement/manageTests";
import RunTests from "../testManagement/runTests";
import "./onDemandStyle.css";

function OnDemandTests() {
    const [selectedButton, setSelectedButton] = useState(null);
    const [showManageTests, setShowManageTests] = useState(false);

    const handleManageTestsClick = () => {
        setShowManageTests(true);
        setSelectedButton('manage');
    };

    const handleTestRunsClick = () => {
        setShowManageTests(false);
        setSelectedButton('run');
    };

    return (
        <div className="on-demand-tests">
            <div className="ondemand-button-container">
                <button
                    className={`manage-tests-btn ${selectedButton === 'manage' ? 'button-selected' : ''}`}
                    onClick={handleManageTestsClick}
                >
                    Manage Tests
                </button>
                <button
                    className={`tests-run-btn ${selectedButton === 'run' ? 'button-selected' : ''}`}
                    onClick={handleTestRunsClick}
                >
                    Test Runs
                </button>
            </div>
            <div className="content-container">
                {showManageTests ? <ManageTests /> : <RunTests />}
            </div>
        </div>
    );
}

export default OnDemandTests;
